import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Modal from 'components/Modal';
import Details from 'components/Details';
import { Wrapper, Item, Background, Filter, Button } from './styled';

export default class PhotoGrid extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string,
        detail: PropTypes.shape({})
      })
    )
  };

  static defaultProps = {
    items: [
      {
        image: '',
        name: '',
        detail: {
          messages: [''],
          figureProps: {
            image: '',
            caption: ''
          }
        }
      }
    ]
  };

  state = {
    showModal: false,
    currentItem: 0
  };

  handleShowModal = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  handleClick = currentItem => {
    this.setState(
      {
        currentItem
      },
      () => {
        this.handleShowModal();
      }
    );
  };

  renderModal = currentItem => {
    const { items } = this.props;
    return (
      <Modal
        showModal={this.state.showModal}
        onRequestClose={this.handleCloseModal}
      >
        <Details
          messages={items[currentItem].detail.messages}
          figureProps={items[currentItem].detail.figureProps}
        />
      </Modal>
    );
  };

  render() {
    const { showModal, currentItem } = this.state;
    const { items } = this.props;
    return (
      <Fragment>
        <Wrapper>
          <Outer>
            <Inner>
              <Row center="xs">
                {items.map((item, i) => (
                  <Col
                    xs={12}
                    sm={5}
                    smOffset={i % 2 === 0 ? 0 : 1}
                    md={3}
                    mdOffset={i % 3 === 0 ? 0 : 1}
                    key={item.name}
                    style={{ display: 'flex' }}
                  >
                    <Item>
                      <Background
                        image={item.image}
                        onClick={() => this.handleClick(i)}
                      />
                      <Filter />
                      <Button onClick={() => this.handleClick(i)}>
                        <FormattedMessage id={item.name} />
                      </Button>
                    </Item>
                  </Col>
                ))}
              </Row>
            </Inner>
          </Outer>
        </Wrapper>
        {showModal ? this.renderModal(currentItem) : null}
      </Fragment>
    );
  }
}
