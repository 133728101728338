import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import ButtonWithDetails from 'components/Button/ButtonWithDetails';
import CalloutStampBlock from 'modules/CalloutStamp';

const Wrapper = styled.div`
  margin: 5rem 0;
  height: auto;
  width: 100%;
`;

const StyledCalloutStamp = styled(CalloutStampBlock)`
  margin: 0;
`;

const StyledButton = styled(ButtonWithDetails)`
  transition: background 500ms ease;

  &:hover {
    background-color: #fff;
  }
`;

function BlockButton() {
  return (
    <Wrapper>
      <Outer>
        <Inner>
          <StyledCalloutStamp
            stamp="greencircle"
            calloutId="enterprises_calloutIntheeu"
          />
          <Row center="xs">
            <Col xs={8} sm={6} md={6} lg={5}>
              <StyledButton
                details={{
                  detailMessages: [
                    'enterprises_visWhyrcoopsDetail1',
                    'enterprises_visWhyrcoopsDetail2',
                    'enterprises_visWhyrcoopsDetail3'
                  ],
                  detailFigure: {
                    image: 'enterprises/detail-coopsareimportant',
                    caption: 'enterprises_imageFarmerseedsCaption'
                  }
                }}
              >
                <FormattedMessage id="enterprises_visWhyrcoops" />
              </StyledButton>
            </Col>
          </Row>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

export default BlockButton;
