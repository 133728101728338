import React from 'react';
import { theme } from 'config/enterprises.json';
import Template from 'modules/ChapterTemplate';
import InfoParallax from 'modules/InfoParallax';
import InfoCollage from 'modules/InfoCollage';
import Videos from 'modules/Videos';
import InfoBoxBlock from 'modules/InfoBox';
import List from 'components/List';
import PhotoGrid from 'modules/PhotoGrid';
import BlockFigure from 'blocks/enterprises/Block-Figure';
import Conclusion from 'modules/Conclusion';
import { withIntl } from 'i18n';
import Noise from 'components/Sound/Noise';
import BlockButton from 'blocks/enterprises/Block-Button';

const Enterprises = () => (
  <Template theme={theme}>
    <Noise id="enterprises/farming" volume={70}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 12,
            smOffset: 0,
            md: 10,
            mdOffset: 1
          },
          image: 'enterprises/farming-colombia',
          caption: 'enterprises_imageNuragroCaption',
          captionPadding: {
            lg: '0'
          }
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 2,
            md: 6,
            mdOffset: 3
          },
          message: 'enterprises_infoAroundtheworld',
          justify: {
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'flex-start'
          }
        }}
        voffset={{
          lg: '30rem 0 0 0',
          md: '25rem 0 0 0',
          sm: '20rem 0 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <Videos videos="enterprises/videoYanyan" />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          sm: 10,
          smOffset: 1,
          md: 6,
          mdOffset: 5,
          lgOffset: 6,
          lg: 7
        },
        layers: [
          'enterprises/strawberries-colombia-1',
          'enterprises/strawberries-colombia-2',
          'enterprises/strawberries-colombia-3'
        ],
        caption: {
          message: 'enterprises_imageLakumbaCaption',
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 2,
            mdOffset: 4,
            md: 6
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 1,
            md: 5,
            mdOffset: 0,
            lg: 6
          },
          message: 'enterprises_infoTherightenviro',
          justify: 'flex-start',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0',
        lg: '0',
        md: '0'
      }}
    />
    <Noise id="enterprises/factory" volume={70}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 11,
            smOffset: 2,
            md: 8,
            mdOffset: 4
          },
          image: 'enterprises/factory-egypt',
          caption: 'enterprises_imageTechnicianCaption'
        }}
        infoBox={{
          infoBox: {
            id: 'enterprises_infoInclusive',
            detailMessages: [
              'enterprises_detailInclusive1',
              'enterprises_detailInclusive2'
            ],
            detailFigure: {
              image: 'enterprises/detail-tokyo',
              caption: 'enterprises_imageTokyoCaption'
            },
            scroll: true,
            size: {
              lg: '28rem',
              md: '25rem',
              sm: '25rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            md: 5,
            mdOffset: 0,
            lgOffset: 0,
            lg: 6
          },
          message: 'enterprises_infoInclusive',
          justify: 'flex-start'
        }}
        voffset={{
          lg: '5rem 0 0',
          md: '8rem 0 0',
          sm: '15rem 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <InfoBoxBlock
      id="enterprises_infoEntrepreneurship"
      infobox={{
        scroll: true
      }}
      col={{
        xs: 12,
        sm: 7,
        smOffset: 2,
        md: 6,
        mdOffset: 3,
        lg: 7,
        lgOffset: 2
      }}
    />
    <List
      items={[
        {
          description: 'enterprises_visStartyourbusHeading',
          subtext: 'enterprises_visStartyourbusBody',
          detailMessages: [
            'enterprises_visStartyourbusDetail1',
            'enterprises_visStartyourbusDetail2'
          ],
          detailFigure: {
            image: 'enterprises/detail-startyourbusiness',
            caption: 'enterprises_imageSiybCaption'
          }
        },
        {
          description: 'enterprises_visKnowyourbusHeading',
          subtext: 'enterprises_visKnowyourbusBody',
          detailMessages: [
            'enterprises_visKnowyourbusDetail1',
            'enterprises_visKnowyourbusDetail2'
          ],
          detailFigure: {
            image: 'enterprises/detail-knowyourbusiness',
            caption: 'enterprises_imageKabCaption'
          }
        }
      ]}
    />
    <Videos videos={['enterprises/videoZeinab', 'enterprises/videoMejia']} />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          sm: 12,
          smOffset: 0,
          md: 9,
          mdOffset: 3,
          lgOffset: 4,
          lg: 10
        },
        layers: [
          'enterprises/designer-lebanon-1',
          'enterprises/designer-lebanon-2',
          'enterprises/designer-lebanon-3'
        ],
        caption: {
          message: 'enterprises_imageKhouryCaption',
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 2,
            mdOffset: 4,
            md: 6
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '33rem',
              md: '25rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            md: 5,
            mdOffset: 0,
            lg: 6
          },
          message: 'enterprises_infoHelpingbus',
          justify: 'flex-end',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0',
        lg: '0 0 15rem',
        md: '0 0 10rem'
      }}
    />
    <PhotoGrid
      items={[
        {
          image: 'enterprises/detail-mycoop',
          name: 'enterprises_visAgriCoopHeading',
          detail: {
            messages: [
              'enterprises_visAgriCoopDetail1',
              'enterprises_visAgriCoopDetail2'
            ],
            figureProps: {
              image: 'enterprises/detail-mycoop',
              caption: 'enterprises_imageMycoopCaption'
            }
          }
        },
        {
          image: 'enterprises/detail-score',
          name: 'enterprises_visResponsibleHeading',
          detail: {
            messages: [
              'enterprises_visResponsibleDetail1',
              'enterprises_visResponsibleDetail2'
            ],
            figureProps: {
              image: 'enterprises/detail-score',
              caption: 'enterprises_imageScoreCaption'
            }
          }
        },
        {
          image: 'enterprises/detail-womenentrepreneur',
          name: 'enterprises_visWomenentrepHeading',
          detail: {
            messages: [
              'enterprises_visWomenentrepDetail1',
              'enterprises_visWomenentrepDetail2'
            ],
            figureProps: {
              image: 'enterprises/detail-womenentrepreneur',
              caption: 'enterprises_imageWedCaption'
            }
          }
        }
      ]}
    />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          md: 7,
          mdOffset: 0
        },
        image: 'enterprises/child-peru',
        caption: 'enterprises_imageChildhuachipaCaption',
        captionAlign: 'ltr',
        captionPadding: {
          lg: '0 50% 0 0',
          md: '0 25% 0 0'
        }
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '30rem',
            md: '20rem'
          }
        },
        colProps: {
          xs: 12,
          smOffset: 3,
          sm: 8,
          md: 5,
          mdOffset: 6,
          lg: 6,
          lgOffset: 5
        },
        message: 'enterprises_infoAddresslegal',
        justify: {
          sm: 'flex-end',
          md: 'flex-end',
          lg: 'flex-end'
        }
      }}
      voffset={{
        lg: '5rem 0 5rem',
        md: '5rem 0 5rem',
        sm: '25rem 0 0',
        xs: '0'
      }}
    />
    <Videos videos="enterprises/videoSims" />
    <Noise id="enterprises/african-market" volume={80}>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            sm: 12,
            smOffset: 0,
            md: 7,
            mdOffset: -1,
            lgOffset: -1,
            lg: 7
          },
          layers: [
            'enterprises/coop-tanzania-1',
            'enterprises/coop-tanzania-2',
            'enterprises/coop-tanzania-3'
          ],
          caption: {
            message: 'enterprises_imageWomencoopCaption',
            colProps: {
              xs: 12,
              sm: 10,
              smOffset: 2,
              mdOffset: 4,
              md: 6
            }
          }
        }}
        infoBoxes={[
          {
            infoBox: {
              scroll: true,
              size: {
                lg: '30rem',
                md: '25rem'
              }
            },
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2,
              mdOffset: 6,
              md: 5,
              lgOffset: 6,
              lg: 6
            },
            message: 'enterprises_infoRethinking',
            justify: 'flex-start',
            position: {
              sm: 'relative'
            }
          }
        ]}
        voffset={{
          sm: '0',
          lg: '0',
          md: '0'
        }}
      />
    </Noise>
    <BlockButton />
    <BlockFigure />
    <Videos videos="enterprises/videoVuuren" />
    <Conclusion
      mainTextId="enterprises_calloutPositiveimpact"
      subTextId="enterprises_calloutAfuture"
    />
  </Template>
);

export default withIntl(Enterprises);
