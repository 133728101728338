import styled from 'styled-components';
import { darkGrey, sans } from 'styles/variables';

export default styled.button`
  background: none;
	color: inherit;
	border: 1px solid ${darkGrey};
	padding: 1rem;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  border-radius: 5px;
  font-family: ${sans};
  font-size: 1rem;
  letter-spacing: 2px;
  width: 100%;
  height: auto;
  text-transform: uppercase;
`;