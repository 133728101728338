import styled from 'styled-components';
import { white, black } from 'styles/variables';
import { paragraphMixin } from 'styles/typography';
import CloudinaryImage from 'components/Image';
import media from 'styles/media';

export const Wrapper = styled.section`
  width: 100%;
  height: auto;
  display: inline-block;
`;

export const ButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  ${paragraphMixin};
  font-weight: 500;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 60%;
  border-radius: 10px;
  border: 1px solid white;
  color: ${white};
  background: none;
  padding: 1rem 0;
  z-index: 1;
  cursor: pointer;
  transition: background 500ms ease, color 500ms ease;

  ${media.md`
    width: 75%;
  `};
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props =>
    `url(${CloudinaryImage.getImageUrlById(props.image)})`};
  background-size: cover;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform ease 500ms;
`;

export const Item = styled.div`
  width: 100%;
  height: auto;
  min-height: 250px;
  overflow: hidden;
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &:hover {
    ${Button} {
      background-color: white;
      color: ${black};
    }

    ${Background} {
      transform: scale(1.1, 1.1);
    }
  }

  ${media.xs`
     &:hover {
      ${Button} {
        background-color: transparent;
        color: ${white};
      }
  `};
`;

export const Filter = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export default Wrapper;
