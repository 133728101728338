import React from 'react';
import Button from 'components/Button';
import WithDetails from 'components/Details/WithDetails';

function ButtonWithDetails({ className, details, children }) {
  return (
    <WithDetails
      details={details}
      toggle={show => (
        <Button className={className} onClick={show}>
          {children}
        </Button>
      )}
    />
  );
}

export default ButtonWithDetails;
