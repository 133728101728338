import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Details from 'components/Details';

const WithDetails = ({ toggle, details }) => {
  const [showModal, setIsShown] = useState(false);
  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const renderModal = () => {
    const { detailFigure, detailMessages } = details;
    if (detailFigure && detailMessages) {
      return (
        <Modal showModal={showModal} onRequestClose={hide}>
          <Details figureProps={detailFigure} messages={detailMessages} />
        </Modal>
      );
    }
    return null;
  };

  return (
    <>
      {toggle(show)}
      {showModal && renderModal()}
    </>
  );
};

export default WithDetails;
