import React from 'react';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Figure from 'components/Figure';

function Block() {
  return (
    <Outer>
      <Inner>
        <Row>
          <Col xs={12}>
            <Figure
              image="enterprises/palestine-dolls"
              caption="enterprises_imagePalestinianCaption"
            />
          </Col>
        </Row>
      </Inner>
    </Outer>
  );
}

Block.propTypes = {};

export default Block;
